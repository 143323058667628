import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="intro-to-organizers" mdxType="Subpage">
      <h3>{`Introduction to Grouping and Structural Markup`}</h3>
      <p>{`Another crucial set of new tags to discuss in this module is a set of structural tags that allow us to further organize our markup. This includes the traditional `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` tag as well as a new set of sectioning tags that are available thanks to HTML5.`}</p>
    </Subpage>
    <Subpage slug="html5-structure" mdxType="Subpage">
      <h3>{`HTML5 Structure`}</h3>
      <Callout number="4.1" term="The 'section' tag..." color="alternate" mdxType="Callout">
        <p>{`...is used to create a significant group composed of one or more elements. Its derivates, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<nav>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<main>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<article>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<aside>`}</code>{`, and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<footer>`}</code>{`,  can be used for more specific kinds of groups.`}</p>
      </Callout>
      <p>{`It’s an appropriate time now to discuss the new structural elements that have enhanced how we can organize and group content, and that we will use later to construct layouts: they all relate to the concept of sectioning.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read Chs. 8 and 17 from Duckett.`}</p>
      </Callout>
      <p>{`Sectioning means dividing our content up into distinct groups of related content. `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<section>`}</code>{` tags (or their derivatives) should be used when…`}</p>
      <ul>
        <li parentName="ul">{`A set of content works as a semantic group, and, ideally, begins with a heading`}</li>
        <li parentName="ul">{`A set of content fills a particular or important role in the context of the site or page and is worth calling out as a significant section of the site`}</li>
      </ul>
      <p>{`The basic sectioning tag is the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<section>`}</code>{` tag. Use this tag to create a section if one of the more specific tags (covered shortly) does not seem appropriate. It is also fine to put section tags within section tags to further subdivide content.`}</p>
      <p>{`The following are derived from the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<section>`}</code>{` tag and provide more specific ways to group content:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<main>`}</code>{` — Almost as open and vague as the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<section>`}</code>{` itself, this tag can be used to contain a typical site's central content that comes between its masthead and footer. More on this to come below...`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<article>`}</code>{` — Use this instead of `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<section>`}</code>{` when the group of content you’re marking could stand completely on its own without surrounding content. Like the name suggests, a blog entry or article in an online newspaper are good examples of when to use this.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<aside>`}</code>{` — Use this specifically to mark sidebar content or groups of content inside an `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<article>`}</code>{` or `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<section>`}</code>{` that are not critical, but tangential content. If the surrounding content would be just fine without the group in question, an `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<aside>`}</code>{` is a good sectioning tag to use.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{` – Use this to mark the heading and any other content that forms the overall `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{` or masthead for a particular set of content. It is common to have an `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<article>`}</code>{` grouping its content, and then inside it to have a `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<section>`}</code>{`, and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<footer>`}</code>{` dividing the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<article>`}</code>{` content into distinct subsections.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<footer>`}</code>{` — Use this to mark content that provides additional information or resources relating to a section of content. Note as well that `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<footer>`}</code>{` and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{` can be applied at the page level to mark persistent site masthead (`}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{`) and site information (`}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<footer>`}</code>{`).`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<nav>`}</code>{` – Use this to mark groups of important navigation links. Every site that has multiple pages should have at least one `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<nav>`}</code>{` section. Note, however, that this should be reserved for important or high-level link groups; it is not necessary for marking links in content.`}</li>
      </ul>
    </Subpage>
    <Subpage slug="common-page-structures" mdxType="Subpage">
      <h3>{`Common Page Structure`}</h3>
      <Callout number="4.2" term="Many sites follow a common structure..." color="alternate" mdxType="Callout">
        <p>{`...that includes the following three components: a masthead, a main content area, and a footer.`}</p>
      </Callout>
      <p>{`Consider the following design:`}</p>
      <p><img parentName="p" {...{
          "src": "/images/organizers-3-parts.png",
          "alt": null
        }}></img></p>
      <p>{`What kind of overall structure could this page have? Allow me to suggest a structure that fits it and could be used with small modifications for many other sites:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token doctype"
            }}>{`<!DOCTYPE html>`}</span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`meta`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`charset`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`utf-8`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`[Site/page title goes here]`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`link`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`type`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`text/css`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`rel`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`stylesheet`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`styles.css`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`head`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`body`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`header`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`site-masthead`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`[site name/brand/logo]`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`nav`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`nav-main`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
                `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
                    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`index.html`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Home`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
                    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`[... other page names wrapped in a link]`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
                `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`nav`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`header`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`main`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`site-content`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`[Page name]`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            [Miscellaneous content; markup will vary based on site]
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`main`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`footer`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`id`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`site-info`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            [Miscellaneous footer content; markup will vary based on site]
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`footer`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`Notice how we have three core groups directly inside `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<body>`}</code>{`:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`A masthead.`}</strong>{` The site's branding is the minimum to include here, very often as the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{` for the site. This also often contains the site's navigation. In some cases the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<nav>`}</code>{` is placed outside the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{` but this is a very common convention to group them together collectively as the masthead.`}</li>
        <li parentName="ul"><strong parentName="li">{`A main content area.`}</strong>{` This has in the past been marked with `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<div>`}</code>{` or `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<section>`}</code>{` but today the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<main>`}</code>{` tag is the best to use for this large and diverse area between the masthead and the footer. The page name is often directly inside this area as well as any content specific to that page grouped further as needed.`}</li>
        <li parentName="ul"><strong parentName="li">{`A footer.`}</strong>{` This often contains a secondary navigation, copyright information, and other content that supports or explains the site itself.`}</li>
      </ul>
    </Subpage>
    <Subpage slug="distinguishing-heads" mdxType="Subpage">
      <h3>{`Distinguish "head..." terms`}</h3>
      <Callout number="4.3" term="Distinguishing 'head...' terms" color="alternate" mdxType="Callout">
        <p>{`Be sure you can distinguish between the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<head>`}</code>{` tag, the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{` tag and the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{` – `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<h6>`}</code>{` tags from each other.`}</p>
      </Callout>
      <p>{`In reference to the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<header>`}</code>{` tag, it is helpful to differentiate this tag from similarly-named but very different tags:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<head>`}</code>{`: the required tag that provides information about the whole HTML document. This one goes right inside the root `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<html>`}</code>{` element before the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<body>`}</code>{` element. Remember, “every HTML document needs a HEAD and a body.”`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{` – `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h6>`}</code>{`: the tags used to mark actual heading text. These can only be placed inside `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<body>`}</code>{` and nested inside other container tags like `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<div>`}</code>{` tags and sectioning tags. Remember, “headINGS label text.”`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<header>`}</code>{`: the sectioning tag used to group headings and other content that marks the start of a section or other group. This must also always be inside the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<body>`}</code>{` tag or nested within other tags that are inside `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<body>`}</code>{`. Remember “headERS group headings and other frontmatter.”`}</li>
      </ul>
    </Subpage>
    <Subpage slug="section-vs-div" mdxType="Subpage">
      <h3>{`Sections vs. Divs`}</h3>
      <Callout number="4.4" term="Distinguish between when to use 'div' and when to use 'section'" color="alternate" mdxType="Callout">
        <p>{`It is best to use `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<div>`}</code>{` for everyday grouping of elements; the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<section>`}</code>{` tags are best reserved for significant groups of content. When in doubt, use a `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<div>`}</code>{`.`}</p>
      </Callout>
      <p>{`Many students struggle to know when they should use sectioning tags and when to use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` tags. In a nutshell, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<section>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<main>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<article>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<aside>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<nav>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<header>`}</code>{`, and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<footer>`}</code>{` should be used to identify significant groups of content that match their grouped intention. On the other hand, the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` tag can be used to provide less significant grouping. Exactly how browsers interpret these tags is an advanced topic you can explore later in your studies. For now, ask yourself, “is this a major `}<em parentName="p">{`section`}</em>{` or a minor `}<em parentName="p">{`div`}</em>{`ision?” If your answer is a, “a major `}<em parentName="p">{`section`}</em>{`,” then consider further the specific content you are grouping to further choose between `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<section>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<article>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<aside>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<nav>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<header>`}</code>{`, and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<footer>`}</code>{`. And don’t be afraid to experiment. The activities that accompany this module provide an opportunity for you to practice and get feedback; solutions can be rather subjective.`}</p>
      <p>{`When the sectioning tags discussed above were released, the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` tag took a much-needed vacation. Before the sectioning tags, the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` was over-worked and underpaid and was used to mark all the different kinds of sections and groups that the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<header>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<footer>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<aside>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<article>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<nav>`}</code>{`, and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<section>`}</code>{` tags better represent. As a result, many developers began to replace all `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` tags with their more appropriate counterparts in the sectioning tags.`}</p>
      <p>{`However, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` still has its uses. The key is that sectioning tags should mark significant sections of content—sections that contribute to the overall structure of the document. Imagine you needed to represent your document in an outline form. What would be the main points in the outline? These things should be marked with sectioning tags. Any sub-points in the outline could also be marked with sectioning tags.`}</p>
      <p>{`There are still many cases where you want to group related content, but that group is not as significant in the scope of the document. In such cases, the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` tag—which stands for “division”—is completely acceptable.`}</p>
    </Subpage>
    <Subpage slug="lists-as-structure" mdxType="Subpage">
      <h3>{`Lists as Structural Markup`}</h3>
      <Callout number="4.5" term="List markup can also be used to create structure." color="alternate" mdxType="Callout">
        <p>{`While `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<ol>`}</code>{` and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<ul>`}</code>{` tags can only contain `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<li>`}</code>{` tags as direct children, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<li>`}</code>{` tags can contain any other kind of tag. This can be very useful for creating complex lists with beautiful, semantic structure.`}</p>
      </Callout>
      <p>{`One additional structural markup element is the un-intimidating `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<li>`}</code>{` element. Remember `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<li>`}</code>{` tags in the context of marking ordered (`}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<ol>`}</code>{`) or unordered (`}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<ul>`}</code>{`) lists? While `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<ul>`}</code>{` and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<ol>`}</code>{` tags can only contain `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<li>`}</code>{` elements, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<li>`}</code>{` tags can contain just about any other tag, from headings and paragraphs to sections and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<div>`}</code>{` tags. Therefore, if a group of content is to be marked that follows a repeating pattern to form a larger list of content, consider using a list.`}</p>
      <p>{`For example, the homepage of many blog websites presents a list of blog posts, as seen in the image on the following page. Each item in that list includes the name, post date, and an excerpt from the entry.`}</p>
      <p><img parentName="p" {...{
          "src": "/images/organizers-blog-list.png",
          "alt": null
        }}></img></p>
      <p>{`In such cases, markup like this could be used:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`News`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-feed`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-head`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`That’s Right, I Said Bootie.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Posted on ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/bootie.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`alt`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`Booties`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-short`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`I have a new favorite in the house ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-foot`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Posted in ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Tagged ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-head`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`First Things First`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Here in Bredebro ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/fall-boots.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`alt`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`Fall boots`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-short`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`I have a new favorite in the house ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-foot`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Posted in ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Tagged ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-head`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Fall 2012 Preview`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Posted on ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/bootie.png`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`alt`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`Booties`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-short`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`One of my favorite brands ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`blog-foot`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Posted in ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
            `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Tagged ...`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`Another example on a smaller scale is a product list, where several pieces of information about a product are presented in a repeatable pattern:`}</p>
      <p><img parentName="p" {...{
          "src": "/images/organizers-product-list.png",
          "alt": null
        }}></img></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Products`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Dress`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`thumb`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/products/boot-1451.jpg`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h4`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Birgmingham Plain Toe Boot GTX`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`price`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`$230.00`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`thumb`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/products/slipon-2954.jpg`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h4`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Pedroso Slip On`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`price`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`$230.00`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`thumb`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/products/shoe-453278.jpg`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h4`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Pedroso Tie`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`price`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`$230.00`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Casual`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`thumb`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/products/shoe-3684.jpg`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h4`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Turn GTX Tie`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`price`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`$190.00`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`thumb`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/products/shoe-4965875.jpg`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h4`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Street Luxe`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`price`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`$180.00`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`thumb`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/products/shoe-9464246.jpg`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h4`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Walker 2.0 Tie`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`price`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`$180.00`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Outdoor`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`thumb`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/products/shoe-8466.jpg`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h4`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Turn GTX Tie`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`price`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`$230.00`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`thumb`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`src`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`images/products/shoe-34586.jpg`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h4`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`name`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Track II Low`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`h4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`price`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`$210.00`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
    </Subpage>
    <Subpage slug="navigation" mdxType="Subpage">
      <h3>{`Navigation Structure`}</h3>
      <p>{`Having identified our basic grouping elements and addressed the fact that lists make great groupers, now is a good time to call out a very common structure for site navigation.`}</p>
      <Callout number="4.6" term="Site navigation..." color="alternate" mdxType="Callout">
        <p>{`...(the persistent navigation at the top of most pages in the site) is often created using a `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<nav>`}</code>{` element that contains a list of hyperlinks that point to the main pages or sections of the site.`}</p>
      </Callout>
      <p>{`Here is what a standard navigation looks like:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`nav`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`class`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`nav-main`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`index.html`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Home`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`about.html`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`About Us`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`products/index.html`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Products`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`news/index.html`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`News`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`href`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`contact.html`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Contact Us`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`nav`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`While the page names and paths will differ from one site to the next, this structure is a common and semantic structure to implement in most sites.`}</p>
    </Subpage>
    <Subpage slug="study-tools" mdxType="Subpage">
      <h3>{`Study Tools`}</h3>
      <p>{`This module’s application activity challenges you to see the invisible structures that group content into layout and conventional elements. Ensure that you can do the following:`}</p>
      <ul>
        <li parentName="ul">{`Recognize and label common layout conventions in design comps.`}</li>
        <li parentName="ul">{`Use `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<div>`}</code>{` tags and the various sectioning tags to organize and group layout elements.`}</li>
      </ul>
      <p>{`Review the key takeaways from this module. Memorize each of the following tags or attributes and understand what they are used for:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<div>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<section>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<main>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<nav>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<header>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<footer>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<article>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<aside>`}</code></li>
      </ul>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      